<template>
	<div class="table-container">
		<div class="">
			<button class="butn-sm butn-info" @click="entraide">Entraide</button>
			<button class="butn-sm butn-info-outline" @click="epargne">Epargne</button>
		</div>

		<div class="table-area">
			<table class="table-content">
				<thead>
					<tr>
						<th>DESCRIPTION</th>
						<th>MONTANT</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th>ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="depense in depenses" :key="depense.id">
						<td>{{ depense.details }}</td>
						<td>{{ depense.montant }}</td>
						<td>{{ depense.type.toUpperCase() }}</td>
						<td>{{ datetime(depense.date) }}</td>
						<td class="d-flex">
							<button
								@click="deleteDepense(depense.id)"
								class="butn butn-close mr-2"
							>
								Supprimer
							</button>
						</td>
					</tr>
					<tr>
						<td><b>TOTAL</b></td>
						<td>
							<b>{{ depensesTotal }}</b>
						</td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="pagination" v-if="page_counts > 1">
			<button
				v-for="no in page_counts"
				:class="{ active: $route.params['current_page'] == no }"
				:key="no"
				@click="goto(no)"
			>
				{{ no }}
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			depenses: this.$store.state.depenses,
			page_counts: 0,
			depense: null,
		};
	},
	watch: {
		"$store.state.depenses"(new_val) {
			this.depenses = new_val;
		},
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		entraide() {
			this.depenses = this.$store.state.depenses.filter(
				(e) => e.type == "entraide"
			);
		},
		epargne() {
			this.depenses = this.$store.state.depenses.filter(
				(e) => e.type == "epargne"
			);
		},
		goto(no) {
			this.$router.push("/depense/" + no);
			this.fetchData();
		},
		fetchData() {
			axios
				.get(
					this.$store.state.url +
						`/depense/?page=${this.$route.params.current_page}`,
					this.header
				)
				.then((res) => {
					this.$store.state.depenses = res.data.results;
					this.page_counts = Math.ceil(
						res.data.count / this.$store.state.page_count
					);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		deleteDepense(ele) {
			if (!confirm("Are you sure !!")) {
				return;
			}

			axios
				.delete(this.$store.state.url + `/depense/${ele}/`, this.header)
				.then((res) => {
					this.fetchData();
				})
				.catch((err) => {
					alert("la somme n'est pas disponible");
					console.error(err);
				});
		},
		updateDepense(depense) {
			this.toggleForm();
		},
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		depensesTotal() {
			let total = 0;

			for (let index = 0; index < this.depenses.length; index++) {
				total += this.depenses[index].montant;
			}
			return total;
		},
	},
};
</script>

<style scoped>
.pagination button {
	padding: 5px 10px;
	margin: 2px;
	background-color: #0000;
}
button{
	margin-right: 5px;
}
h3 {
	font-size: 25px;
	margin: 10px 0;
}
@media screen and (max-width: 600px) {
	.table-content {
		width: 700px;
	}
}
</style>
