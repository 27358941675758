import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user : null,
    url: "/api",
    // url: "http://127.0.0.1:8000/api",
    logged_in : false,
    epargnes : [],
    credits : [],
    save_user : true,
    members : [],
    selectedMembre : null ,
    depenses : [],
    retraits : [],
    mes_retrait : [],
    page_count: 20,
    operations: [],
    depots: {},
    detailsDepots: {},
    capitalData: {},
    paiment_mensuels: {},
    users: [],
    comptes: [],
    keyword: "",
    dashboard: {
      somme_entraide: 0,
      somme_epargne: 0,
      benefice: 0,
      depense: 0,
    },
    exercices: {},
    profile: {},
    comptePrincipal: {},
    liste_membres: {},
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
