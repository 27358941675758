<template>
	<div>
		<div class="top">
			<h3>Liste des depenses</h3>
			<button @click="toggleForm" type="button" class="butn butn-primary">
				<i class="fa fa-plus"></i> Nouvel Depense
			</button>
		</div>
		<depense-liste @updateDepense="updateDepense" />
		<modal-depense :depense="depense" @close="toggleForm"
			:revele="revele" :toggleForm="toggleForm"/>
	</div>
</template>

<script>
import DepenseListe from "../components/DepenseListe.vue";
import ModalDepense from "../components/ModalDepense.vue";
export default {
	components: { DepenseListe, ModalDepense },
	data() {
		return {
			revele: false,
			depense: null,
		};
	},
	methods: {
		toggleForm() {
			this.revele = !this.revele;
		},
		updateDepense(val) {
			this.depense = val;
			this.toggleForm();
		},
	},
};
</script>

<style scoped>
.top{
	display: flex;
	justify-content: space-between;
}
@media screen and (max-width: 576px) {
	.main-container {
		max-width: 430px;
	}
}
</style>
