<template>
	<div>
		<div class="input-group">
			<input type="text" @keydown="(e) => searchValue(e)"
				autocomplete="false" placeholder="Rechercher"/>
			<button class="butn butn-secondary" type="button">
				<i class="fa fa-search"></i>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		searchValue(e) {
			this.$store.state.keyword = e.target.value;
		},
	},
};
</script>

<style scoped>
template > div {
	width: 100%;
}
.main {
	width: 50%;
	margin: 50px auto;
}
.has-search .form-control {
	padding-left: 2.375rem;
}
.input-group {
	position: relative;
	width: 230px
}
.form-input {
   width: 100%;
   border: 1px solid #343a4055;
   box-shadow: none;
   height: 39px;
}
.butn {
   position: absolute;
   top: 0;
   right: 0;
   background: #343a40;
   border-radius: 0;
   height: 100%;
}
/* 
.has-search .form-control-feedback {
	position: relative;
	z-index: 2;
	display: block;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	text-align: center;
	pointer-events: none;
	color: #aaa;
} */

@media screen and (max-width: 600px) {
	.input-group {
		min-width: 100px;
	}
	.butn {
		padding: 0 8px;
	}
	.form-input {
		height: 30px;
		padding: 3px 5px;
	}


}
</style>
