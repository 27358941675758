<template>
	<div class="bloc-modale">
		<div class="overlay"></div>
		<div class="modale card">
			<div class="btn btn-danger close-btn" @click="close">X</div>
			<h5>{{  save ? "Nouveau Membre": "Modification" }}</h5>
			<p>{{ errorResponse.user.last_name }}</p>

			<div class="card-modal mt-1">
				<div class="field mt-2">
					<label for="" class="mb-0">NOM</label>
					<input v-model="form.user.first_name"
						class="form-control" type="text"
						placeholder="nom"/>
					<span class="text-danger" v-if="errorResponse.user.username">
						Ce champs est obligatoire
					</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">PRENOM</label>
					<input
						v-model="form.user.last_name"
						class="form-control"
						type="text"
					/>
					<span class="text-danger" v-if="errorResponse.user.last_name"> Ce champs est obligatoire</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">EMAIL</label>
					<input
						v-model="form.user.email"
						class="form-control"
						type="email"/>
					<span class="text-danger" v-if="errorResponse.user.email">
						{{ errorResponse.user.email[0]}}
					</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">TELEPHONE</label>
					<input v-model="form.telephone"
						class="form-control" type="text"/>
					<span class="text-danger" v-if="errorResponse.telephone"> Ce champs est obligatoire</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">NOM D'UTILISATEUR</label>
					<input v-model="form.user.username"
						class="form-control" type="text"/>
					<span class="text-danger" v-if="errorResponse.user.username">
						Ce champs est obligatoire
					</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">MOT DE PASSE</label>
					<input v-model="form.user.password"
						class="form-control" type="password"/>
					<span class="text-danger" v-if="errorResponse.user.password"> Ce champs est obligatoire</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">EPARGNE</label>
					<input v-model="form.somme_epargne"
						class="form-control" type="number"/>
					<span class="text-danger" v-if=" errorResponse.somme_epargne"> Ce champs est obligatoire</span>
				</div>
				<div class="field mt-2">
					<label for="" class="mb-0">ENTRAIDE</label>
					<input v-model="form.somme_entraide"
						class="form-control" type="number"/>
				</div>
				
				<div v-if="save" class="btn-wrapper">
					<button class="butn butn-info" @click="saveMember">
						Enregistrer
					</button>
				</div>
				<div v-else class="field btn-wrapper">
					<button class="butn butn-close" @click="updateMembre">
						Modifier
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
export default {
     props:["revele","toggleForm","selectedMembre"],
     data(){
         return{
            form:{
                    user: {
                    password: "",
                    is_superuser: false,
                    username: "",
                    first_name: "",
                    last_name:"",
                    email: ""
                },
                telephone: "",
                somme_epargne: null,
                somme_entraide: null
            },
           
            val : {},
           
            membre_id : "",
            error : {},
            errorResponse : {user : {}}
         }
     },
     mounted() {
                
     },
     watch:{
         "$store.state.selectedMembre"(new_val){
            if(!!new_val){
               
                this.form.user.username = new_val.user.username
                this.form.user.is_superuser = new_val.user.is_superuser
                this.form.user.first_name = new_val.user.first_name
                this.form.user.last_name = new_val.user.last_name
                this.form.user.email = new_val.user.email
                this.form.telephone = new_val.telephone
                this.form.somme_epargne  = new_val.somme_epargne
                this.form.somme_entraide = new_val.somme_entraide
                this.membre_id = new_val.id                
                this.titleModal = "Modifier le Membre"
                
            }else{
                this.titleModal = "Nouveau Membre"
                this.membre_id = ""
            }      
         }
    }
    ,
  computed:{
        header(){
			return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
		},
		save(){
			return this.$store.state.save_user
		}
    },
    methods: {
        checkValideForm(){
            if(this.form.user.username  == ""){
                this.error.username = "* Champs obligatoire"
            }
            
            if(this.form.user.first_name  == ""){
                this.error.first_name = "* Champs obligatoire"
            }
            if(this.form.user.last_name  == ""){
                this.error.last_name = "* Champs obligatoire"
            }
            if(this.form.user.email  == ""){
                this.error.email = "* Champs obligatoire"
            }
            if(this.form.user.telephone  == ""){
                this.error.telephone = "* Champs obligatoire"
            }
            if(this.form.user.somme_epargne  == ""){
                this.error.somme_epargne = "* Champs obligatoire"
            }
            if(this.form.user.somme_epargne  == ""){
                this.error.somme_entraide = "* Champs obligatoire"
            }
        },
        saveMember(){
            this.checkValideForm()
            axios.post(this.$store.state.url + "/compte/", this.form, this.header)
            .then(res => {
                console.log(res)
                this.$emit("updateMessage", "Enregistrement réussi")
                this.fetchData()
                this.close()
            })
            .catch(err => {
                this.errorResponse = err.response.data
               // alert("ERREUR")
                console.log(err.response.data); 
            })
        },
        updateMembre(){
            axios.patch(this.$store.state.url + "/compte/"+this.membre_id+"/",this.form ,this.header)
            .then(res => {
                this.$emit("updateMessage", "Modification réussi")
                this.fetchData()
                this.close()
            })
            .catch(err => {
                alert("ERROR")
                this.$emit("toggleForm")
                console.error(err); 
            })
        },
        close(){
            this.form={
                    user: {
                    password: "",
                    is_superuser: false,
                    username: "",
                    first_name: "",
                    last_name:"",
                    email: ""
                },
                telephone: "",
                somme_epargne: null,
                somme_entraide: null
            },
			this.$store.state.save_user = true;
            this.$emit("close", null),
            this.errorResponse = { user:{}}
        },
        fetchData(){
      axios
      .get(this.$store.state.url + "/compte/?page=1", this.header)
      .then((res) => {
        console.log(res)
        this.$store.state.members = res.data.results;
        //this.page_counts = Math.ceil(res.data.count/this.$store.state.page_count)
        this.page_counts = Math.ceil(res.data.count/this.$store.state.page_count)
      })
      .catch((err) => {
		
        console.log("ERROR");
      });
    }
    },
};
</script>
<style scoped>
.field label {
	color: #000;
	text-align: left;
	display: block;
}
.field input {
	border: 1px solid rgb(66, 66, 66);
	display: block;
	width: 100%;
}
select {
	border: 1px solid black;
}
h5 {
	margin: 5px 0 0;
}
/* .field *{
    display: block!important;
} */
/** POUR LE MODAL */
.bloc-modale {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 250;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}
.modale {
	position: fixed;
	top: 6%;
	max-height: 100%;
	overflow-y: visible;
}
.card-modal {
	padding: 0 20px 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.card-modal .field {
	width: 48%;
	margin-top: 10px;
}

.close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	display: block;
	padding: 5px 12px;
	border-radius: 3px;
	color: #fff;
	background: #dc3545;
}
.butn {
	width: 48%;
}
.btn-wrapper {
	text-align: right;
	width: 100%;
	margin-top: 10px;
}
.card {
	max-width: 45rem;
	height: auto;
}

@media screen and (max-width: 800) {
	.card {
		max-width: 30rem;
	}
}

@media screen and (max-width: 600px) {
	.modale {
		top: 3%;
	}
	.card {
		max-width: 25rem;
	}
	.card {
		display: block;
		height: 572px;
	}
	.card-modal .field {
		width: 100%;
	}
	.butn {
		width: 100%;
		margin-bottom: 20px;
	}
}
</style>
