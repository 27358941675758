<template>
	<div>
		<div class="top">
			<h3>Liste des retraits</h3>
			<search-bar style="margin-top: 10px" />
		</div>
		<div>
			<button v-if="utilisateur.is_admin" type="button" @click="fetchData"
				class="butn butn-primary">
				Tout les retraits
			</button>
			<div class="table">
				<table class="table-content">
					<thead>
						<tr>
							<th>COMPTE</th>
							<th>SOMME TOTAL</th>
							<th>SOMME RETIRE</th>
							<th>SOMME RESTANT</th>
							<th>DATE</th>
							<th v-if="utilisateur.is_admin">ACTION</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="retrait in retraits" :key="retrait.id">
							<td>{{ retrait.compte.user.first_name}} {{ retrait.compte.user.last_name}}</td>
							<td>{{ formatNumber(retrait.somme_total) }}</td>
							<td>{{ formatNumber(retrait.somme_retire) }}</td>
							<td>{{ formatNumber(retrait.somme_restant) }}</td>
							<td>{{ datetime(retrait.generated_at) }}</td>
							<td v-if="utilisateur.is_admin">
								<button class="butn-sm butn-primary" @click="toggleForm(retrait)">Retrait</button>
							</td>
						</tr>
						<tr>
							<td>TOTAL</td>
							<td>
								<b>{{ formatNumber(retraitsTotal.somme_total) }}</b>
							</td>
							<td>
								<b>{{ formatNumber(retraitsTotal.somme_retire)}}</b>
							</td>
							<td>
								<b>{{ formatNumber(retraitsTotal.somme_restant)}}</b>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<retrait-modal :retrait="retrait" @close="toggleForm"
					:revele="revele" :toggleForm="toggleForm"/>

				<div class="pagination" v-if="page_counts > 1">
					<button v-for="no in page_counts" :key="no" @click="goto(no)"
						:class="{ active: $route.params['current_page'] == no }">
						{{ no }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import RetraitModal from '../components/RetraitModal.vue';
import SearchBar from '../components/SearchBar.vue';
export default {
  components: { RetraitModal, SearchBar },
	data() {
		return {
			retraits: this.$store.state.retraits,
			page_counts: 0,
			revele: false,
			retrait : {}
		};
	},
	watch: {
		"$store.state.retraits": {
			deep: true,
			handler(new_val) {
				this.retraits = new_val;
			},
		},
		"$store.state.keyword"(new_val) {
			let retrait = "";
			this.retraits = this.$store.state.retraits.filter((x) => {
				retrait = JSON.stringify(x);
				return retrait
					.toLocaleLowerCase()
					.includes(new_val.toLocaleLowerCase());
			});
		},
	},
	mounted() {
		
		this.fetchDataForConnectedUser();
		this.fetchData()
	},
	methods: {
		toggleForm(retrait){
			//console.log(retrait)

			this.revele = !this.revele;
			this.retrait = retrait

		},
		fetchDataForConnectedUser() {
			axios
				.get(this.$store.state.url + `/retrait/me/`, this.header)
				.then((res) => {
					this.$store.state.retraits = res.data;
					this.page_counts = Math.ceil(
						res.data.count / this.$store.state.page_count
					);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		fetchData() {
			axios
				.get(
					this.$store.state.url +
						`/retrait/?page=${this.$route.params.current_page}`,
					this.header
				)
				.then((res) => {
					this.$store.state.retraits = res.data;
					this.page_counts = Math.ceil(
						res.data.count / this.$store.state.page_count
					);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		goto(no) {
			this.$router.push("/retrait/" + no);
			this.fetchData();
		},
		genererRetrait() {
			axios
				.get(this.$store.state.url + "/retrait/generate/", this.header)
				.then((res) => {
					this.response = "opération terminé";
				})
				.catch((err) => {
					// console.error(err);
					this.response = "already generated";
				});
		},
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		utilisateur() {
			return this.$store.state.user;
		},
		retraitsTotal() {
			// retrait.somme_total }}</td>
			// <td>{{ retrait.somme_retire }}</td>
			// <td>{{ retrait.somme_restant
			let total = {
				somme_retire: 0,
				somme_restant: 0,
				somme_total: 0,
			};

			for (let index = 0; index < this.retraits.length; index++) {
				//const element = array[index];
				total.somme_retire += this.retraits[index].somme_retire;
				total.somme_restant += this.retraits[index].somme_restant;
				total.somme_total += this.retraits[index].somme_total;
			}
			return total;
		},
	},
};
</script>

<style scoped>
h3 {
  font-size: 25px;
  text-transform: capitalize;
}
.top{
	display: flex;
	justify-content: space-between;
}
@media screen and (max-width: 576px) {
	.main-container {
		max-width: 430px;
	}
}
</style>
