<template>
	<div class="bloc-modale" v-if="revele">
		<div class="overlay"></div>
		<div class="modale card">
			<div class="btn btn-danger close-btn" @click="toggleForm">X</div>
			<h5>Nouvelle Crédit</h5>
			<div class="card-modal mt-4">
				<div class="field mt-2">
					<label for="somme">MONTANT</label>
					<input
						class=""
						type="number"
						v-model.number="form.montant"
						name=""
					/>
				</div>
				<div class="field mt-2">
					<label for="somme">PERIODE</label>
					<input
						class=""
						type="number"
						v-model.number="form.periode"
						name=""
					/>
				</div>
				<div class="field mt-2">
					<label for="somme">TAUX %</label>
					<input
						class=""
						type="number"
						v-model.number="form.taux"
						:disabled="sans_interet"
						name=""
					/>
				</div>

				<div class="check mt-2">
					<input
						class=""
						id="sans_interet"
						type="checkbox"
						v-model="sans_interet"
						name=""
					/>
					<label for="sans_interet">CREDIT SANS INTERET</label>
				</div>

				<div class="field mt-3">
					<div v-if="loader" class="d-flex justify-content-center">
						<div class="spinner-border text-warning" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<label for="montant"> </label>
					<button
						v-if="!loader"
						@click="saveCredit"
						class="butn butn-info"
					>
						Valider
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
  props: ["revele", "toggleForm", "comptes"],
  data() {
    return {
      form: {
        montant: "",
        periode: "",
        taux: 5,
    
      },
      sans_interet : false,
      loader : false,
      error : {}
    };
  },
  watch:{
    sans_interet(new_val){
  
      if(new_val){
      
        this.form.taux = 0
      }else{
        this.form.taux = 5
      }
    }
  },
  methods: {
    checkForm(){
      
    },
    saveCredit() {
      this.loader = true
      axios.post(this.$store.state.url + "/credit/", this.form, this.header)
        .then((res) => {
          //console.log(res.data)
            this.$store.state.credits.unshift(res.data);
            this.loader = false
            this.toggleForm();
          alert("REUSSI");
        })
        .catch((err) => {
          this.error = err.response.data;
           console.error(err);
           this.loader = false
        
         
        });
    }
  },
  computed: {
    header() {
      return {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.access}`,
        },
      };
    },
  },
};
</script>

<style scoped>
h5 {
	margin: 5px 0px 0;
}
.field {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	margin-top: 10px;
}
.field label,
.field select,
.field input {
	display: block;
	width: 100%;
	color: black;
	text-align: left;
	margin: 0;
}
.field select,
.field input {
	height: 35px;
}

/** POUR LE MODAL */
.bloc-modale {
	position: fixed;
	top: 15;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 250;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

.modale {
	position: fixed;
	top: 10%;
	height: auto;
}
.card-modal {
	padding: 20px;
	width: auto;
}
.check {
	margin-top: 10px;
}

.close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	display: block;
	padding: 5px 12px;
	border-radius: 3px;
	color: #fff;
	background: #dc3545;
}
.butn {
	width: 100%;
}
</style>
