<template>
	<div>

		<div class="table">
			<table class="table-content">
				<thead>
					<tr>
						<th>ENTRAIDE</th>
						<th>EPARGNE</th>
						<th>DATE</th>
						<th>ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="depot in depots" :key="depot.id" v-if="depot.entraide >0 ||depot.epargne>0 " >
						<td>{{ formatNumber(depot.entraide) }}</td>
						<td>{{ formatNumber(depot.epargne) }}</td>
						<td>{{ datetime(depot.date) }}</td>
						<td>
							<button @click="detailDepot(depot.id)">Afficher</button>
						</td>
					</tr>
					<tr></tr>
				</tbody>
			</table>
		</div>
		<div class="pagination" v-if="page_counts > 1">
			<button
				v-for="no in page_counts"
				:class="{ active: $route.params['current_page'] == no }"
				:key="no"
				@click="goto(no)"
			>
				{{ no }}
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			page_counts: 0,
			mois: "",
			depots: this.$store.state.depots,
		};
	},
	computed:{
		depots(){			
			return  this.$store.state.depots
		}
		
	},
	updated() {
		this.getSeleData();
		this.fetchData()
		
	},

	mounted() {
		this.getSeleData();
		this.fetchData()
	},
	watch: {
		"$store.state.depots"(new_val) {
			// this.depots = new_val
		},
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		totalDepot() {
			let total = 0;
			for (let index = 0; index < this.depots.length; index++) {
				total += this.depots[index].somme;
			}
			return total;
		},
	},
	methods: {
		goto(no) {
			this.$router.push("/depot/" + no);
			this.fetchData();
		},

		getSeleData() {
			axios
				.get(this.$store.state.url + `/depot/`, this.header)
				.then((res) => {
					//console.log(res)
					this.$store.state.depots = res.data.results;
					//this.page_counts = Math.ceil(res.data.count/this.$store.state.page_count)
				})
				.catch((err) => {
					console.error(err);
				});
		},

		detailDepot(id){
			 this.$router.push("/detail-depot/"+ id)
		},

		fetchData() {
			axios
				.get(
					this.$store.state.url +
						`/depot/?page=${this.$route.params.current_page}`,
					this.header
				)
				.then((res) => {
					//console.log(res)
					this.$store.state.depots = res.data.results;
					this.page_counts = Math.ceil(
						res.data.count / this.$store.state.page_count
					);
				})
				.catch((err) => {
					console.error(err);
				});
		},
		somme_entraide() {
			this.depots = this.$store.state.depots.filter(
				(depot) => depot.details == "somme_entraide"
			);
		},
		somme_epargne() {
			this.depots = this.$store.state.depots.filter(
				(depot) => depot.details == "somme_epargne"
			);
		},
		searchV() {
			axios
				.get(
					this.$store.state.url + "/depot/month/" + this.mois + "/",
					this.header
				)
				.then((res) => {
					console.log(res);
					this.$store.state.depots = res.data;
				})
				.catch((err) => {
					console.error(err);
				});
			console.log(this.mois);
		},
	},
};
</script>
<style scoped>
.pagination button {
	padding: 5px 10px;
	margin: 2px;
	background-color: #0000;
}
button.active {
	background-color: #33bf7a;
}

.btn-grp {
    width: 900px;
    margin: 15px auto 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
	overflow-x: auto;
}

.butn-sm {
    font-size: 14px;
    text-transform: capitalize;
}
@media screen and (max-width: 600px) {
    .btn-grp {
		display: flex;
		justify-content: unset;
		flex-wrap: nowrap;
		width: 100%;
		padding-bottom: 10px;
    }
    .butn-sm {
        padding: 3px 8px;
        font-size: 12px;
		max-height: 35px;
		margin-right: 5px;
    }
    input {
        height: 35px;
		min-width: 80px;
    }
	.show {
		min-width: 150px;
	}
    .table-content {
        width: 700px;
    }
}
</style>
