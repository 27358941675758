<template>
	<div>
		<planetChart />
	</div>
</template>

<script>
import PlanetChart from "../components/PlanetChart.vue";

export default {
	name: "App",
	components: {
		PlanetChart,
	},
};
</script>
