<template>
	<div>
		<button @click="toggleForm" class="butn butn-info">
			Voir le tableau d'amortissement
		</button>
		<modal-amortissement
			@doSomething="caculerAmortissement"
			v-bind:revele="revele"
			v-bind:toggleForm="toggleForm" />

		<div class="table-area">
			<table class="table-content">
				<thead>
				
					<tr>
						<th>N°</th>
						<th>Date</th>
						<th>Capital restant dû</th>
						<th>Capital remboursé</th>
						<th>Intérêts</th>
						    <th>Cumul des intérêts</th>
						<th>Mensualité</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="element in tableau_amortissement"
						:key="element.id"
					>
						<td>{{ element.echeance + 1 }} </td>
						<td>{{ months[(element.date_debut - 1 )% 12 ]  }} - {{ element.annee}} </td>
						<td>{{ formatNumber(element.capital_restant) }}</td>
						<td>{{ formatNumber(element.capital_rembourse) }}</td>
						<td>{{ formatNumber(element.interet_mensuel) }}</td>
						<td>{{ formatNumber(element.cumule_interet) }}</td>
						<td>{{ formatNumber(element.mensualite) }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import ModalAmortissement from "./ModalAmortissement.vue";
export default {
	components: { ModalAmortissement },
	data() {
		return {
			tableau_amortissement: [],
			revele: false,
		};
	},
	computed:{

		 months(){
           return  [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre'
                    ] 
        }
	},

	methods: {
		toggleForm() {
			this.revele = !this.revele;
		}, 
		caculerAmortissement(data) {
			
			let capital_emprunter = data.montant;
			let taux_interet_mensuel = data.taux / 100 / 12;
			let dure_pret = data.periode;
			let mensualite =
				capital_emprunter *
				(taux_interet_mensuel /
					(1 - (1 + taux_interet_mensuel) ** -dure_pret));
				if(data.taux == 0){
					mensualite = capital_emprunter / dure_pret
				}

			let tableau_amortissement = [];

			let interet_mensuel = capital_emprunter * taux_interet_mensuel;
			let capital_rembourse = mensualite - interet_mensuel;
			let capital_restant = capital_emprunter - capital_rembourse;
			let cumule_interet = interet_mensuel
			let date_debut = data.date_debut
			let annee = data.annee

			let paiement_mensuel = {
				date_debut : data.date_debut,
				annee : data.annee,
				capital_restant: capital_restant,
				interet_mensuel: interet_mensuel,
				capital_rembourse: capital_rembourse,
				mensualite: mensualite,
				echeance: 0,
				cumule_interet : cumule_interet,
				capital_emprunter : capital_emprunter,
				dure_pret : dure_pret,
				taux_interet_mensuel : taux_interet_mensuel,
			};

			this.$store.state.capitalData = paiement_mensuel

			tableau_amortissement.push(paiement_mensuel);

			for (let i = 1; i < dure_pret; i++) {
				interet_mensuel =
					tableau_amortissement[i - 1]["capital_restant"] *
					taux_interet_mensuel;
				capital_rembourse =
					mensualite -
					interet_mensuel +
					tableau_amortissement[i - 1]["capital_rembourse"];
				capital_restant =
					tableau_amortissement[i - 1]["capital_restant"] -
					(mensualite - interet_mensuel);
				cumule_interet += interet_mensuel
				date_debut = date_debut + 1;
				annee = tableau_amortissement[i - 1]["annee"]

				if(date_debut %12 == 0){
					console.log(tableau_amortissement[i - 1]["annee"])
					annee = (tableau_amortissement[i - 1]["annee"] *1)  + 1
				}

				paiement_mensuel = {
					capital_restant: capital_restant,
					interet_mensuel: interet_mensuel,
					capital_rembourse: capital_rembourse,
					mensualite: mensualite,
					echeance: i,
					annee : annee,
					cumule_interet : cumule_interet,
					date_debut : date_debut,
				};

				tableau_amortissement.push(paiement_mensuel);
			}

			this.tableau_amortissement = tableau_amortissement;
		},
	},
};
</script>

<style scoped>
/* Tables */
.table-area {
	width: 100%;
	overflow-x: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}
/* Tables End */

@media screen and (max-width: 600px) {
   .butn {
      padding: 5px 15px;
      font-size: 14px;
   }
	.table-content {
		width: 700px;
	}
}
</style>
