<template>
<div>
    <h3 class="info">Information</h3>
    <div class="info-container">
        <p><span>Nom et prénom :</span> {{utilisateur .fullname}}</p>
        <p><span>Epargne :</span> {{utilisateur.somme_epargne}}</p>
        <p><span>Entraide :</span> {{utilisateur.somme_entraide}}</p>
        <p><span>E-mail :</span> {{profil.user.email}}</p>
        <p><span>Nom d'utilisateur :</span> {{profil.user.username}}</p>

        <button @click="openChangePassWord" class="btn btn-warning">Modifier votre mot de passe</button>
        <p class="info-container">
         <span>{{ message }} </span>
        </p>
    </div>
    <div v-if="changePassword" class="info-container">
          
        
            <div class="">
                    <label for="somme">ANCIEN MOT DE PASSE</label> <br> 
                    <input class="" type="password" v-model.number="form.old_password" name="">
            </div>
             <div class="">
                    <label for="somme">NOUVEAU MOT DE PASSE</label> <br>
                    <input class="" type="password" v-model.number="form.new_password" name="">
            </div>
             <div class="">
                    <label for="somme">CONFIRMER VOTRE MOT DE PASSE</label> <br>
                    <input class="" type="password" v-model.number="form.confirm_password" name="">
            </div>
            <div>
            <span class="errorr">{{errorr}}</span>
            </div>
            <div>
                <button @click="changeMotPass" class="btn btn-primary">Valider</button>
            </div>
            
    </div>

</div> 
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            form: {
                new_password : "",
                old_password : "",
                confirm_password : "",
            },
            changePassword : false,
            errorr : "",
            message : ""
            
        }
    },
    computed:{
        utilisateur(){
            return this.$store.state.user
        
        },
        header(){
            return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
        },
        profil(){
            return this.$store.state.profile
        },
        
    },
    mounted(){
        axios.get(this.$store.state.url+`/compte/${this.utilisateur.id}/`,this.header)
        .then(res => {
            this.$store.state.profile = res.data
        })
        .catch(err => {
            console.error(err); 
        })
    },
    methods:{
        changeMotPass(){

            if(this.form.new_password !== this.form.confirm_password){

                  this.message = "les 2 mots de passe ne sont pas identiques."
            }else{

                 let data = {

                    'old_password': this.form.old_password + "",
                    'new_password': this.form.new_password + ""
                }
                console.log(data)
                
            axios.post(this.$store.state.url+`/compte/changepassword/`,data,this.header)
                .then(res => {
                   // this.$store.state.profile = res.data 
                    this.message = "modification réussie​"
                    this.openChangePassWord()
                })
                .catch(err => {
                     this.message = err.response.data.status
                    console.error(err); 
                })

            }
           

               
        },
        openChangePassWord(){
            this.changePassword = !this.changePassword
        }
    }
}
</script>
<style scoped>
.errorr{
    color: red;
}
.info-container {
    width: 500px;
    margin: auto;
    text-align: left;
}
span {
    font-weight: 800;
    font-size: 18px;
}
</style>