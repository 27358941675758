<template>
	<div class="table-container">
		<div v-if="message" class="alert alert-primary" role="alert">
			{{ message }} 
		</div>
		<div v-if="getMessage" class="alert alert-primary" role="alert">
			{{ getMessage }} 
		</div>
		<div v-if="rembourseError.status" class="alert alert-danger" role="alert">  
			{{rembourseError.status}}
		</div>
        <button class="butn" @click="mesCredits"> MES CREDITS </button>
		<button v-if="utilisateur.is_admin" @click="toutCredits"class="butn">
			Tous les crédits
		</button>
        <button v-if="utilisateur.is_admin" class="butn" @click="imprimerCredit"> IMPRIMER </button>
		<div class="table">
			<table class="table-content">
				<thead>
					<tr>
						<th>CREDIT NO</th>
						<th>COMPTE</th>
						<th>MONTANT</th>
						<th>TAUX</th>
						<th>RESTE</th>
						<th>DATE DU DEBUT</th>
						<th>PERIODE</th>
						<th>ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="credit in credits" :key="credit.id">
						<td>{{ credit.id }}</td>
						<td>
                            {{ credit.compte.user.first_name }}
                            {{ credit.compte.user.last_name }}
                        </td>
						<td>{{ formatNumber(credit.montant) }}</td>
						<td>{{ credit.taux }}</td>
						<td>{{ formatNumber(credit.reste) }}</td>
						<td>{{ credit.date_debut }}</td>
						<td>{{ credit.periode }}</td>
						<td>
							<button
								v-if="!credit.is_valid && utilisateur.is_admin"
								class="butn-sm butn-primary mr-2"
								@click="valideCredit(credit)">
								Valider
							</button>
							<button
								v-if="!credit.is_valid"
								class="butn-sm butn-close mr-2"
								@click="deleteCredit(credit)">
								Annuler
							</button>
							<button
								v-if="credit.is_valid"
								class="butn-sm butn-info  mr-2"
								@click="showAmortissement(credit)">
								Detail
							</button>
							<button
								v-if="credit.is_valid && utilisateur.is_admin"
								class="butn-sm butn-info-outline  mr-2"
								@click="getLiquidation(credit)">
								Liquider
							</button>
                            
                            <button
								v-if="!credit.is_valid && !utilisateur.is_admin"
								class="butn-sm butn-info-outline  mr-2"
								@click="showLiquidataion(credit)">
								A Liquider
							</button>

							<button
								v-if="credit.is_valid && utilisateur.is_admin"
								class="butn-sm butn-info  mr-2"
								@click="rambourser(credit)">
								Rembourser
							</button>

                            <button
								v-if="credit.is_valid && utilisateur.is_admin"
								class="butn-sm butn-info  mr-2"
								@click="demandeLettre(credit)">
								Imprimer
							</button>
						</td>
					</tr>

                    <tr>
                        <td colspan="2">TOTAL</td>
                        <td>{{ formatNumber(totalCredits.totalCredit)}}</td>
                         <td></td>
                        <td>{{ formatNumber(totalCredits.totalReste)}}</td>
                        <td></td>
                    </tr>
				</tbody>
			</table>
		</div>
		<modale-paiement v-bind:showPaiement="showPaiement" />

        <div id="print">
            	<div class="header_print">
					<div>
					<h2>CAISSE SOCIAL</h2>
					</div>

					<div> 
					<h2> {{new Date().toDateString()}} </h2>
					</div>
				 </div>
            <table>
                <thead>
                    <tr> 
                        <th>NOM</th>
                        <th>PRENOM</th>
                        <th>MONTANT</th>
                        <th>TAUX</th>
                        <th>RESTE</th>
                        <th>DATE</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="credit in credits" :key="credit.id">
						<td>{{ credit.id }}</td>
						<td>{{ credit.compte.user.first_name }}
                        {{ credit.compte.user.last_name }}
                        
                        </td>
						<td>{{ credit.montant }}</td>
						<td>{{ credit.taux }}</td>
						<td>{{ credit.reste }}</td>
                        <td>{{ credit.date_debut }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
	</div>
</template>
<script>
import axios from 'axios'
import Paginate from 'vuejs-paginate'
import ModalePaiement from './ModalePaiement.vue'
export default {
    components:{Paginate, ModalePaiement, },
    data() {
        return {
            credits : this.$store.state.credits,
            checks : [],
            creditsValides : [],
            showPaiement : false,
            message : "",
            getMessage : "",
            rembourseError : {}
        }
    },
    watch: {
        "$store.state.credits"(new_val){
            this.credits = new_val
        },
    },
    methods: {
        demandeLettre(credit){
            console.log(credit)
            let information = credit.id + "*" + credit.compte.user.first_name  +"*"
                 +credit.compte.user.last_name + "*" + credit.montant +"*"+credit.date_debut+"*"+credit.periode +
                 "*"+credit.taux
            this.$router.push("/demande-lettre/"+ information)

        },
        getLiquider(credit){
            axios.get(this.$store.state.url+"/credit/"+ credit.id+"/liquider/" ,this.header)
            .then(res => {
                alert("OPERATION REUSSI")
                this.fetchOneData()
            })
            .catch(err => {
                console.error(err); 
            })
        },
        rambourser(credit){

            var a = prompt(
				"Entre votre nom pour la confirmation : " + this.$store.state.user.fullname,
				""
			);
			if (a && a.toLowerCase() == this.$store.state.user.fullname.toLowerCase()) {

            axios.get(this.$store.state.url+"/credit/"+ credit.id+"/rembourser/" ,this.header)
            .then(res => {
                alert("VOUS AVEZ PAYE : "
                     +res.data.a_payer + "\n AVEC INTERET : "
                    +res.data.interet + "\n IL VOUS RESTE A PAYER : " + res.data.reste
                )

                this.fetchOneData()
            })
            .catch(err => {
                this.rembourseError = err.response.data
                console.log(err.response.data); 
            })
            }      
        },
        showLiquidataion(credit){
            
            axios.get(this.$store.state.url+"/credit/"+ credit.id+"/liquidation/" ,this.header)
            .then(res => {
                const response = confirm("IL VOUS RESTE A PAYE : \n CAPITAL RESTANT : " +(res.data.capital_restant) + 
            "\n BENEFICE : "+(res.data.benefice) + "\n");
                if(response){
                    // axios.get(this.$store.state.url+"/credit/"+ credit.id+"/liquider/" ,this.header)
                    // .then(res => {
                    //    this.getMessage = "Liquidation réussi"
                    // })
                    // .catch(err => {
                    //     console.error(err); 
                    // })
                }
            })
            .catch(err => {
                console.error(err); 
            })


        },
        getLiquidation(credit){

            axios.get(this.$store.state.url+"/credit/"+ credit.id+"/liquidation/" ,this.header)
            .then(res => {
                const response = confirm("IL VOUS RESTE A PAYE : \n CAPITAL RESTANT : " +(res.data.capital_restant) + 
            "\n BENEFICE : "+(res.data.benefice) + "\n");
                if(response){
                    axios.get(this.$store.state.url+"/credit/"+ credit.id+"/liquider/" ,this.header)
                    .then(res => {
                       this.getMessage = "Liquidation réussi"
                    })
                    .catch(err => {
                        console.error(err); 
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        showAmortissement(credit){
            this.$router.push("/amortissement-list/"+ credit.id)
        },
        valideCredit(credit){
            // console.log(credit)
            if(confirm("Vous êtes sûr  ?")){
                axios.get(this.$store.state.url+"/credit/"+ credit.id+"/validate/" ,this.header)
                .then(res => {
                    this.fetchData()
                })
                .catch(err => {
                    this.message = "la somme n'est pas disponible"
                    console.error(err); 
                })
            }
            
        },
         clickCallback: function(pageNum) {
                axios.get(this.$store.state.url+"/credit/?page="+pageNum,this.header)
                .then(res => {    
                    this.credits = res.data
                })
                .catch(err => {
                    console.error(err); 
                })
        },
        listcreditsValides(){},
        deleteCredit(credit){
            if(confirm("Vous êtes sûr d'annuler ce Crédit")){
                axios.delete(this.$store.state.url+"/credit/"+ credit.id+"/" ,this.header)
            .then(res => {
                this.fetchData()
                console.log(res)
                //this.$store.state.credits.results = this.$store.state.credits.results.filter(c => c.id != credit.id)
            })
            .catch(err => {
                console.error(err); 
            })
            }
            
        },
        fetchData(){
        axios.get(this.$store.state.url+"/credit/",this.header)
        .then(res => {
            this.$store.state.credits = res.data
        })
        .catch(err => {
            console.error(err); 
        })
        },
        fetchOneData(){
            axios.get(this.$store.state.url+"/credit/me/",this.header)
            .then(res => {
                this.$store.state.credits = res.data
            })
            .catch(err => {
                console.error(err); 
            })
        },
        toutCredits(){
            this.fetchData()
        },
        mesCredits(){
            this.fetchOneData()
        },

        imprimerCredit(){

            const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

            WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

            WinPrint.document.close();
			WinPrint.focus();
            WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);
        }
    },
    mounted() {
        this.fetchOneData()
        
    },
    computed:{
        header(){
            return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
        },
        nombrePage(){
            return Math.ceil(this.credits.count / 10)
        },
        utilisateur(){
            return this.$store.state.user
        },
        totalCredits(){
           let totalCredit = 0
           let totalReste = 0
           for (const credit of  this.$store.state.credits) {
               totalCredit += credit.montant
               totalReste += credit.reste
               
           }

           return {
                totalCredit : totalCredit,
                totalReste : totalReste
           }
        }
        
    },
}
</script>

<style scoped>
h3 {
	font-size: 25px;
	margin: 15px 0;
}
.ml-3 {
    margin-right: 15px;
}
#print{
    display:none;
}
@media screen and (max-width: 600px) {
	.butn {
		padding: 6px 12px;
        margin-bottom: 8px;
	}
}
</style>
