<template>
	<div>
		<div class="top">
			<h3>Liste des membres</h3>
			<searchbar />
		</div>
		<div class="options">
			<button @click="printMember">
				Imprimer
			</button>
			<button @click="toggleForm">
				Ajouter un membre
			</button>
			<button @click="getActiveUser">
				Liste des membres actifs
			</button>
			<button @click="getDesactiveUser">
				Liste des membres Désactivés
			</button>
		</div>
		<div v-if="messageInfo">
			<div class="alert alert-primary" role="alert">
				{{ messageInfo }}
			</div>
		</div>

		<ModaleMember @updateMessage="updateMessage"
			:class="{ hidden: !revele }" @close="revele = false"
			:selectedMembre="selectedMembre"/>

		<div class="table">
			<table class="table-content">
				<thead class="text-center">
					<tr>
						<th>NOM ET PRENOM</th>
						<th>COMPTE</th>
						<th>TELEPHONE</th>
						<th>EPARGNE</th>
						<th>ENTRAIDE</th>
						<th>TOTAL EPARGNE </th>
						<th>TOTAL SOCIAL</th>
						<th>ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr></tr>
					<tr v-for="membre in members" :key="membre.id">
						<td>
							{{ membre.user.first_name }}
							{{ membre.user.last_name }}
						</td>
						<td>{{ membre.num_compte }}</td>
						<td>{{ membre.telephone }}</td>
						<td>{{ formatNumber(membre.somme_epargne) }}</td>
						<td>{{ formatNumber(membre.somme_entraide) }}</td>
						<td>
							{{formatNumber(membre.total_epargne)}}
						</td>
						<td>
							{{ formatNumber(membre.total_entraide)}}
							</td>
						<td class="d-flex">
							<button class="blue" 
								v-if="membre.user.is_active"
								@click="modifierMembre(membre)">
								Modifier
							</button>
							<button
								v-if="user().is_admin && membre.user.is_active"
								@click="gestionnaireMembre(membre)">
								{{ nominationLabel(membre) }}
							</button>
							<button class="red" 
								v-if="user().is_admin && membre.user.is_active"
								@click="deleteMember(membre)">
								limoger
							</button>
							<button
								v-if="user().is_admin && !membre.user.is_active"
								class="butn-sm butn-primary"
								@click="activerMember(membre)">
								Activer
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="pagination" v-if="page_counts > 1">
			<button
				v-for="no in page_counts" :key="no"
				:class="{ active: $route.params['current_page'] == no }"
				@click="goto(no)">
				{{ no }}
			</button>
		</div>

		<div id="print" class="hidden">
			<div class="header_print">
				<div>
					<h2>CAISSE SOCIAL</h2>
				</div>
				<div> 
					<h2> {{new Date().toDateString()}} </h2>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th>NOM</th>
						<th>PRENOM</th>
						<th>EPARGNE</th>
						<th>SOCIAL</th>
						<th>TOTAL EPARGNE </th>
						<th>TOTAL SOCIAL</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="membre in liste_membres" :key="membre.id">
						<td>{{membre.user.first_name}}</td>
						<td>{{membre.user.last_name}}</td>
						<td>{{membre.somme_epargne}}</td>
						<td>{{membre.somme_entraide}}</td>
						<td>{{membre.total_epargne}}</td>
						<td>{{membre.total_entraide}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import ModaleMember from "../components/ModaleMember.vue";
import searchbar from "../components/SearchBar.vue";
import axios from 'axios'

export default {
	components: { ModaleMember, searchbar },
	data() {
		return {
			revele: false,
			selectedMembre: {},
			searchName: "",
			messageInfo: "",
			members: this.$store.state.members,
			page_counts: 0,
		};
	},
	mounted() {
		axios.get(this.$store.state.url+"/compte/all/",this.header)
		.then(res => {
			this.$store.state.liste_membres = res.data
		})
		.catch(err => {
			console.error(err); 
		})
		this.fetchData();
	},
	watch: {
		"$store.state.members"(new_val) {
			this.members = new_val;
		},
		"$store.state.keyword"(new_val) {
			let member = "";
			this.members = this.$store.state.members.filter((x) => {
				member = JSON.stringify(x);
				return member
					.toLocaleLowerCase()
					.includes(new_val.toLocaleLowerCase());
			});
		},
	},
	methods: {
		toggleForm() {
			this.selectedMembre = {};
			this.revele = !this.revele;
		},
		modifierMembre(ele) {
			this.$store.state.selectedMembre = ele;
			this.revele = true;
			this.$store.state.save_user = false;
		},
		updateMessage(message) {
			this.messageInfo = message;
		},
		printMember(){
			//let liste_membres = []
		
			const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

			WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

			WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);
		},
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		nombrePage() {
			return Math.ceil(this.members.count / this.members.results.length);
		},
		user() {
			return this.$store.state.user;
		},
		fetchData() {
			let url = this.$store.state.url +"/compte/?page=" +this.$route.params["current_page"]
			axios.get(url, this.header)
			.then((res) => {
				console.log(res);
				this.$store.state.members = res.data.results;
				//this.page_counts = Math.ceil(res.data.count/this.$store.state.page_count)
				this.page_counts = Math.ceil(
					res.data.count / this.$store.state.page_count
				);
				this.getActiveUser();
			}).catch((err) => {
				console.error(err);
			});
		},
		nominationLabel(membre){
			if(membre.user.is_superuser &&(membre.user.id !=  this.user.id) && membre.user.is_active){
				return "Destituer"
			}
			if(!membre.user.is_superuser &&(membre.user.id !=  this.user.id) && membre.user.is_active){
				return "Gerant"
			}
		},
		getActiveUser() {
			this.members = this.$store.state.members.filter(
				(item) => item.user.is_active
			);
		},
		getDesactiveUser() {
			this.members = this.$store.state.members.filter(
				(item) => !item.user.is_active
			);
		},
		gestionnaireMembre(m) {
			const current_droit = !m.user.is_superuser;
			let number = Math.floor(Math.random() * 99999);
			var givenNumber = prompt("Tapez : " + number + " Pour confirmer");
			const data = {
				user: {
					is_superuser: current_droit,
				},
			};
			if (givenNumber == number) {
				let url = this.$store.state.url + "/compte/" + m.id + "/"
				axios.patch(url, data, this.header)
				.then((res) => {
					if (current_droit) {
						alert("GESTIONNAIRE");
					} else {
						alert("DESTITUER");
					}
					this.fetchData();
				})
				.catch((err) => {
					alert("ERROR");
				});
			}
		},
		deleteMember(membre) {
			console.log(membre.id)
			const response = confirm("Are you sure ? ");
			if (response) {
				let url = this.$store.state.url + "/compte/" + membre.id + "/"
				axios.delete(url, this.header).then((res) => {
					membre.user.is_active = false
				}).catch((err) => {
					if(err.response) alert(err.response.data.status);
				});
			}
		},
		activerMember(membre){
			const response = confirm("Are you sure ? ");
			if (response) {
				let url = `${this.$store.state.url}/compte/${membre.id}/activate/`
				axios.get(url, this.header)
				.then((res) => {
					membre.user.is_active = true
				}).catch((err) => {
					if(err.response) alert(err.response.data.status);
				});
			}
		},
		goto(no) {
			this.$router.push("/membre/" + no);
			this.fetchData();
		},
	},
	computed:{
		header(){
            return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
        },
		liste_membres(){
			return this.$store.state.liste_membres
		}
	}
};
</script>
<style scoped>
.hidden {
	display: none;
}
h3 {
	font-size: 22px;
	text-transform: capitalize;
	margin-bottom: 15px;
}
.top{
	display: flex;
	justify-content: space-between;
}
.options>button{
	margin-right: 5px;
}
.red{
	background-color: #955;
	color: #eee;
	border: 2px solid #955;
}
.blue{
	background-color: #589;
	color: #eee;
	border: 2px solid #589;
}
@media screen and (max-width: 600px) {
	.main-container {
		max-width: 400px;
	}
	.d-flex {
		display: block;	
	}

	.butn {
		padding: 7px 12px;
		margin-top: 10px;
		margin-right: auto;
		margin-left: auto;
		display: block;
	}
}
</style>
