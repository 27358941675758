<template>
	<div>
		<div class="btn-align">
			<button @click="toggleForm" type="button">
				Demande de crédit <i class="fa fa-plus"></i>
			</button>
			<router-link to="/amortissement"
				active-class="active" tag="button" exact
				class="butn-sm butn-info-outline">
				Tableau d'amortissement
			</router-link>
		</div>
		<modale-credit
			v-bind:comptes="comptes"
			v-bind:revele="revele"
			v-bind:toggleForm="toggleForm"
		/>
		<modale-demande-credit
			v-bind:reveleDemande="reveleDemande"
			v-bind:toggleFormDemande="toggleFormDemande"
		/>
		<credit-list />
	</div>
</template>
<script>
import CreditList from "../components/CreditList.vue";
import ModaleCredit from "../components/ModaleCredit.vue";
import ModaleDemandeCredit from "../components/ModaleDemandeCredit.vue";
import axios from "axios";

export default {
	components: { CreditList, ModaleCredit, ModaleDemandeCredit },
	data() {
		return {
			comptes: [],
			revele: false,
			reveleDemande: false,
		};
	},
	mounted() {
		axios
			.get(this.$store.state.url + "/compte/", this.header)
			.then((res) => {
				this.comptes = res.data;
			})
			.catch((err) => {
				console.error(err);
			});
		
	},
	methods: {
		toggleForm() {
			this.revele = !this.revele;
			axios
				.get(this.$store.state.url + "/compte/", this.header)
				.then((res) => {
					this.comptes = res.data;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		toggleForm() {
			this.revele = !this.revele;
		},
		toggleFormDemande() {
			this.reveleDemande = !this.reveleDemande;
		},
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		utilisateur() {
			return this.$store.state.user;
		},
	},
};
</script>
<style scoped>
.btn-align{
	display: flex;
	justify-content: flex-end;
}
.btn-align>*{
	margin-left: 5px;
	height: 30px;
}
.butn-sm {
   margin-right: 5px;
   margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
	.main-container {
		max-width: 430px;
	}
   .btn-align {
      text-align: center;
   }
   .btn-align .butn-sm {
	   margin-bottom: 10px;
   }
   .btn-align .butn-sm:last-child {
	   margin-bottom: 0px;
   }
}

</style>
